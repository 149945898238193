.equipo-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.equipo-scroller {
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.equipo-section {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  padding: 1em;
  box-sizing: border-box;
}

.equipo-team-image {
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../assets/images/team_javer.jpg");
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.equipo-team-image > button {
  border: none;
  outline: none;
  border-radius: 0.25em;
  padding: 1em;
  color: white;
  background-color: var(--main-red);
  cursor: pointer;
  margin: 2em;
  text-decoration: underline;
}

.equipo-title {
  color: white;
}

.equipo-title {
}

.equipo-title > h1 {
  margin: 0;
  font-family: "Viga";
  font-size: 4rem;
  line-height: 1em;
}

.equipo-title > h1 > span {
  color: var(--main-red);
}

.equipo-title > h1:nth-of-type(1) {
  margin-right: 1.5em;
  text-align: left;
}

.equipo-title > h1:nth-of-type(2) {
  margin-left: 1.5em;
  text-align: right;
}

.equipo-title > h2 {
  align-self: stretch;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
}

.equipo-team-image > sub {
  font-size: 1rem;
  color: var(--main-grey);
  font-family: "Viga";
}

.equipo-team-image > sub:nth-of-type(1) {
  margin-top: 1em;
}
.equipo-team-image > sub:nth-of-type(2) {
  margin-bottom: 1em;
}

@media (max-width: 1000px) {
  .flex-grow-port-only {
    flex-grow: 1;
  }

  .equipo-title > h1 {
    margin: 0;
    font-family: "Viga";
    font-size: 2.5em;
    line-height: 1em;
  }

  .equipo-team-image > sub {
    margin-left: 1em;
    margin-right: 1em;
    text-align: center;
  }

  .equipo-team-image > button {
    font-size: 1em;
  }
}
