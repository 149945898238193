.favourites-outer {
  position: absolute;
  top: 3rem;
  right: 1.5em;
  background-color: white;
  max-height: calc(100vh - 4rem);
  border-radius: 6px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  color: var(--main-grey);
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 99999;
}
.favourites-top-bar {
  display: flex;
}
.favourites-title {
  flex-grow: 1;
  padding-top: 0.6rem;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 2.5em;
}
.favourites-close {
  min-width: 2.5em;
  max-width: 2.5em;
  height: 2.5em;
  background-image: url("../assets/images/close-cross.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60% 60%;
  cursor: pointer;
}
.favourites-scroller {
  flex-grow: 1;
  overflow: scroll;
}

.favourite-item {
  display: flex;
  opacity: 1;
  flex-direction: column;
  justify-content: center;
  min-width: 260px;
  align-items: center;
  margin-left: 2em;
  margin-right: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid var(--light-grey);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 1;
  margin-top: 1em;
  transition: all 1s;
}

.favourite-item.disable {
  opacity: 0;
}

.placeholder-login-favorites {
  width: 260px;
  padding: 2rem 1rem;
  margin: 0 auto;
  font-size: 1rem;
  text-align: center;
}

.favourite-thumbnail {
  transition: all 1s;
  height: auto;
  width: 16rem;
  max-height: 8rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
}

.favourite-thumbnail > img {
  width: 100%;
  height: auto;
}

.favourite-thumb-row {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.favourite-thumb-text-col {
  align-self: stretch;
  margin: 1rem 0;
  flex-grow: 1;
}

.favourite-thumb-text-col > h2 {
  font-family: "Viga";
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
  margin-right: 1rem;
}

.favourite-thumb-text-col > p {
  margin: 0;
  font-size: 0.95rem;
  font-weight: 400;
}

.favourite-thumb-dots {
  display: flex;
  flex-direction: column;
  padding: 1em;
  cursor: pointer;
}

.favourite-thumb-dots > div {
  width: 0.5em;
  height: 0.5em;
  border-radius: 100%;
  background-color: var(--main-grey);
  margin: 0.125em;
}

.favourite-status {
  background-color: var(--light-grey);
  color: white;
  border-radius: 0.25em;
  padding: 1em;
  margin-right: 2em;
}

.ftrow-2 {
  justify-content: flex-start;
  align-self: stretch;
}

.ftrow-2 > svg {
  height: 1.3rem;
  width: 1.3rem;
  transition: all 0.25s;
  color: var(--main-grey);
  cursor: pointer;
}

.ftrow-2 > svg.active-favorite {
  color: var(--main-red);
}

/* @media (max-width: 1000px) {
  TODO: make better responsive
 .favourites-outer {
    right: 0.5em;
    left: 0.5em;
    bottom: 0.5em;
  }
} */
