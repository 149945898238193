.lotes-title-image {
  width: 100vw;
  background-position: center;
  background-size: cover;
  color: var(--main-red);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.lotes-title-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 40em;
}

.lotes-title-text > h1 {
  font-size: 2.5rem;
  margin: 3rem 0 1rem 0;
  padding: 0;
  text-align: center;
  font-family: "Viga";
}

.lotes-title-text > h2 {
  padding: 0 1rem;
  font-size: 1rem;
  font-weight: 700;
  max-width: 100vw;
  text-align: center;
  margin-bottom: 2.5rem;
}

.adp {
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .lotes-title-text > h1 {
    font-size: 2rem;
  }
  .lotes-title-text > h2 {
    font-size: 0.9rem;
  }
}

/* Styles dropdown already in page contactos */
