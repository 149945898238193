@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&family=Viga&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply !text-5xl lg:!text-7xl font-viga font-normal;

  * {
    @apply !text-5xl lg:!text-7xl font-viga font-normal;
  }
}

h2 {
  @apply !text-4xl lg:!text-6xl font-viga font-normal;

  * {
    @apply !text-4xl lg:!text-6xl font-viga font-normal;
  }
}

h3 {
  @apply !text-3xl lg:!text-5xl font-viga font-normal;

  * {
    @apply !text-3xl lg:!text-5xl font-viga font-normal;
  }
}

h4 {
  @apply !text-2xl lg:!text-4xl font-viga font-normal;

  * {
    @apply !text-2xl lg:!text-4xl font-viga font-normal;
  }
}

h5 {
  @apply !text-xl lg:!text-3xl font-viga font-normal;

  * {
    @apply !text-xl lg:!text-3xl font-viga font-normal;
  }
}

h6 {
  @apply !text-lg lg:!text-2xl font-viga font-normal;

  * {
    @apply !text-lg lg:!text-2xl font-viga font-normal;
  }
}

.blog-titles > :is(h1, h2, h3, h4, h5, h6) {
  @apply mt-8 mb-4;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 9%;
    --foreground-soft: 0 0% 26%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 357 86% 43%;
    --primary-foreground: 0 0% 100%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 357 86% 43%;
    --accent-foreground: 0 0% 100%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

div:has(iframe[title="Botmaker"]) {
  bottom: 4rem !important;
  right: 0rem !important;
  left: inherit !important;
  max-height: 80vh !important;
}

div:has(iframe[title="Botmaker"]).hidden-bot {
  visibility: hidden;
}

body {
  scroll-behavior: smooth;
}

.text-stroke {
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 1px;
  color: transparent;
}

.svg-shadow {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3))
    drop-shadow(0 1px 2px rgba(0, 0, 0, 0.3));
}
